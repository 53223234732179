import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { loadState } from './localStorage';
import { dispatchMiddleware, localStorageMiddleware, offerEvents } from './middleware';
import { waitUntilOffersReady } from './waitUntilOffersReady';
import { offerRequestMiddleware } from './offerRequest';

export function makeStore(reducer, ...extraMiddlewares) {
  if (window.og && window.og.store) return window.og.store;

  const isPreviewMode = window.og && window.og.previewMode;

  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          name: 'Ordergroove Offers'
        })
      : compose;

  const middlewares = [
    waitUntilOffersReady,
    thunk, // remove thisone after migration, it will save some KB
    offerRequestMiddleware,
    dispatchMiddleware,
    offerEvents
  ];

  let initial = {};

  if (!isPreviewMode) {
    try {
      initial = loadState();
      middlewares.push(localStorageMiddleware);
    } catch (err) {
      // localStorage not available during preview in sandbox
    }
  }

  const sortedMiddlewares = extraMiddlewares
    .filter(it => (it?.position || 0) < 0)
    .concat(middlewares)
    .concat(extraMiddlewares.filter(it => it && (it?.position || 0) >= 0));

  const enhancer = composeEnhancers(applyMiddleware(...sortedMiddlewares));

  const store = createStore(reducer, initial, enhancer);

  window.og = window.og || {};
  window.og.store = store;
  return store;
}

export default makeStore;
